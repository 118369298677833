<template>
  <notificationDropdown />

  <!--  &lt;!&ndash; NOTIFICATIONS &ndash;&gt;-->
<!--  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">-->
<!--    <feather-icon-->
<!--      icon="BellIcon"-->
<!--      class="cursor-pointer mt-1 sm:mr-6 mr-2"-->
<!--      :badge="unreadNotifications.length"-->
<!--    />-->

<!--    <vs-dropdown-menu-->
<!--      class="notification-dropdown dropdown-custom vx-navbar-dropdown"-->
<!--    >-->
<!--      <div class="notification-top text-center p-5 bg-primary text-white">-->
<!--        <h3 class="text-white">0 New</h3>-->
<!--        <p class="opacity-75">Notifications</p>-->
<!--      </div>-->

<!--      <VuePerfectScrollbar-->
<!--        ref="mainSidebarPs"-->
<!--        class="scroll-area&#45;&#45;nofications-dropdown p-0 mb-10"-->
<!--        :settings="settings"-->
<!--        :key="$vs.rtl"-->
<!--      >-->
<!--        <ul class="bordered-items">-->
<!--          <li-->
<!--            v-for="ntf in unreadNotifications"-->
<!--            :key="ntf.index"-->
<!--            class="flex justify-between px-4 py-4 notification cursor-pointer"-->
<!--          >-->
<!--            <div class="flex items-start">-->
<!--              <feather-icon-->
<!--                :icon="ntf.icon"-->
<!--                :svgClasses="[-->
<!--                  `text-${ntf.category}`,-->
<!--                  'stroke-current mr-1 h-6 w-6'-->
<!--                ]"-->
<!--              ></feather-icon>-->
<!--              <div class="mx-2">-->
<!--                <span-->
<!--                  class="font-medium block notification-title"-->
<!--                  :class="[`text-${ntf.category}`]"-->
<!--                  >{{ ntf.title }}</span-->
<!--                >-->
<!--                <small>{{ ntf.msg }}</small>-->
<!--              </div>-->
<!--            </div>-->
<!--            <small class="mt-1 whitespace-no-wrap">{{-->
<!--              elapsedTime(ntf.time)-->
<!--            }}</small>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </VuePerfectScrollbar>-->

<!--      <div-->
<!--        class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer"-->
<!--      >-->
<!--        <span>View All Notifications</span>-->
<!--      </div>-->
<!--    </vs-dropdown-menu>-->
<!--  </vs-dropdown>-->
</template>

<script>
import notificationDropdown from "@/views/general-component/notificationDropdown";

export default {
  components: {
    notificationDropdown
  }
};
</script>
