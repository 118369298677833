const isAUregion = process.env.VUE_APP_REGION === "AU";
const isUSregion = process.env.VUE_APP_REGION === "US";
// const isNZregion = process.env.VUE_APP_REGION === "NZ";

/** 
 * valid names: production | staging | uat
 * usage: isEnv('staging')
 */
const isEnv = (name) => (process.env.VUE_APP_ENV === name)

/** AU, US and NZ*/
export const isStaging = isEnv('staging');
export const isUat = isEnv('uat');
export const isProduction = isEnv('production');

/** AU */
export const isStagingAU = isEnv('staging') && isAUregion;
export const isUatAU = isEnv('uat') && isAUregion;
export const isProductionAU = isEnv('production') && isAUregion;

/** US */
export const isStagingUS = isEnv('staging') && isUSregion;
export const isUatUS = isEnv('uat') && isUSregion;
export const isProductionUS = isEnv('production') && isUSregion;

/** US */