<template>
  <div class="sticky">
      <div class="p-0 md:p-5 bg-white" :class="classObj + `${!sidebar ? ' w-full' : ''}` ">

      <div v-if="showCloseCta" class="flex items-center justify-center relative p-3">
        <button @click="closeIframeView" class="cursor-pointer p-3 absolute left-0 border-none bg-transparent">
          <feather-icon icon="ArrowLeftIcon" class="text-primary2" />
        </button>
        <template v-if="showTitle">
            <h1 class="text-xl font-semibold"> Resource Hub </h1>
          </template>
      </div>

      <vs-navbar v-if="!isIframe" class="vx-navbar navbar-custom navbar-skelton shop-nav" :color="navbarColorLocal" :class="{'p-0':isShopPages,textColor}">
        <div class="flex justify-between items-center w-full padder z-40 bg-white" style="height:46px;" :class="{'px-5 pt-4':isShopPages,'xl:justify-end':!isShopPages}">
          <div class=" flex">

          <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2 pl-0  text-primary2 " icon="MenuIcon" @click.stop="showSidebar" />
            <div
              v-if="isShopPages"
              class="md:hidden vx-logo cursor-pointer flex items-center"
              @click="redirectToShopHome"
            >
              <img
                class="mr-2 fill-current text-primary"
                style="width: 28px"
                :src="require('../../../../../public/Group-543.svg')"
              />
              <img
                :src="require('../../../../../public/Group-544.svg')"
                width="100px"
              />
            </div>
          </div>
          <div v-if="isShopPages" class="hidden flex-col ease-in duration-300 md:flex mr-4">
            <ClinicSwap title="Delivery to" />
          </div>


          <OrgOwnerXeroConnection />

          <!-- <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" /> -->

          <!-- <vs-spacer /> -->

          <!-- <i18n /> -->
          <!-- desktop -->
          <SearchInput v-if="isShopPages" class="hidden md:flex w-full" />

          <!-- <search-bar /> -->

          <!-- <cart-drop-down /> -->

          <!-- <active-clinic /> -->
          <div class="flex flex-row justify-items-end">
            <template v-if="!isIframe">
              <cart-drop-down v-if="showCart" />
            </template>
            <org-owner-notification-drop-down />
            <org-owner-profile-drop-down v-if="showUserDropdown"/>
          </div>

        </div>




        <div class="flex items-center w-full md:hidden px-5 justify-between bg-white py-2" v-if="isShopPages"  >
          <Navbar v-if="isShopPages" class="md:hidden border-0 pt-3"/>
          <SearchInput class="w-full justify-end flex" />
        </div>

        <vs-divider v-if="isShopPages" class="hidden md:block" />
        <Navbar v-if="isShopPages" class="hidden md:block border-0 pt-3"/>
        <div  id="deliver_to" class="px-5 py-3 flex md:hidden  justify-between w-full items-center gap-2 box-border z-20 " style="background: #F9F9F9; top:95px;" v-if="isShopPages" >
            <ClinicSwap title="Deliver to:" class="px-5"/>
        </div>

        <OrgOwnerAlertBanner id="alert_banner_mobile" class=" " v-if="isShopPages"/>
        

      </vs-navbar>
    </div>
  </div>
</template>
<script>

import OrgOwnerNotificationDropDown from "../components/org-owner/OrgOwnerNotificationDropDown.vue";
import OrgOwnerProfileDropDown from "../components/org-owner/OrgOwnerProfileDropDown.vue";
import OrgOwnerXeroConnection from "./OrgOwnerXeroConnection";
import CartDropDown from "../components/org-owner/ClinicCartDropDown.vue";
import LDClient from "launchdarkly-js-client-sdk";
import { mapActions } from "vuex";
import ClinicSwap from "../../../../views/components/shop/clinic.vue";
import SearchInput from "../../../../views/components/shop/Search.vue";
import SideBarCategories from "../../../../views/components/shop/SideBarCategories.vue"
import Navbar from "../../../../views/components/shop/Navbar.vue";
import OrgOwnerAlertBanner from "../../alert-banner/orgOwnerAlertBanner.vue"

export default {
  name: "TheOrgOwnerNavbarVertical",
  data: () => ({
    clinicCompanyName: "",
    showCart: false,
    isIframe: false,
    showTitle: false
  }),
  props: {
    navbarColor: {
      type: String,
      default: "#fff",
    },
    sidebar: {
      type: Boolean,
      default: true
    },
    showUserDropdown: {
      type: Boolean,
      default: true
    },
    showCloseCta:{
      type: Boolean,
      default: false
    }
  },
  components: {
    // Bookmarks,
    // I18n,
    // SearchBar,
    CartDropDown,
    OrgOwnerNotificationDropDown,
    OrgOwnerProfileDropDown,
    // ActiveClinic,
    OrgOwnerXeroConnection,
    ClinicSwap,
    SearchInput,
    SideBarCategories,
    Navbar,
    OrgOwnerAlertBanner
  },
  computed: {
    navbarColorLocal() {
      return this.$store.state.theme === "dark" && this.navbarColor === "#fff"
        ? "#10163a"
        : this.navbarColor;
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    textColor() {
      return {
        "text-white":
          (this.navbarColor != "#10163a" &&
            this.$store.state.theme === "dark") ||
          (this.navbarColor != "#fff" && this.$store.state.theme !== "dark"),
      };
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // NAVBAR STYLE
    // eslint-disable-next-line vue/return-in-computed-property
    classObj() {
      if (this.verticalNavMenuWidth == "default") return "navbar-default";
      else if (this.verticalNavMenuWidth == "reduced") return "navbar-reduced";
      else if (this.verticalNavMenuWidth) return "navbar-full";
    },
    isShopPages() {
      return `${this.$route.path}`.includes("/shop") || `${this.$route.path}`.includes("/product/") || `${this.$route.path}`.includes("/checkout")
    },
    baseUrl: function(){
      const host = window.location.protocol + "//" + window.location.host
      const userPath = `${this.$route.path}`.split( '/' )[1]
      return `${host}/${userPath}`
    }
  },
  methods: {
    closeIframeView() {
      const windowTemp = window;
      windowTemp.webkit.messageHandlers.jsMessageHandler.postMessage(
        'close-screen'
      );
    },
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    ...mapActions("appUser", ["fetchAppUserIndex"]),
    onLaunchDarklyReady() {
      if (this.ldClient.allFlags().shop) {
        // this.showCart = this.ldClient.allFlags().shop;
      }
    },
    redirectToShopHome(){
        const userType = JSON.parse(
            localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)
        ).userType;
        const role = localStorage.getItem(
            `${process.env.VUE_APP_ACCESS_TOKEN_NAME}Role`
        );

        let routerName = "";
        if (role === "Org Owner") {
            routerName = "OrgOwnerOrderCenter";
        } else {
            if (userType === "doctor") {
                routerName = "DoctorOrderCenter";
            } else {
                routerName = "NurseOrderCenter";
            }
        }
        this.$router.push({
            name: routerName});
        },

  },
  beforeMount: async function () {
    const userIndex = await this.fetchAppUserIndex();
    const { badge: key, email } = this.$store.state.AppActiveUser;
    var user = {
      key: userIndex.data.data.count,
      email: email,
    };
    this.ldClient = LDClient.initialize(process.env.VUE_APP_LDCLIENT_KEY, user);
    this.ldClient.on("ready", this.onLaunchDarklyReady);
    this.ldClient.on("change", this.onLaunchDarklyReady);
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    // Check if the page is loaded inside an iframe
    const iframeView = localStorage.getItem("iframe-view");
    if(iframeView && iframeView.length) {
      const closeCta = JSON.parse(iframeView);
      this.isIframe = closeCta.closeCta;  
    }
    
    this.showTitle = this.$route.name === 'resources';
  },
  unmounted () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  watch: {
    "$store.state.showStore": {
      handler(val) {
        this.showCart = val;
      },
      immediate: true
    },
    "isShopPages": {
      handler(val){
        if(!val){
            var element = document.getElementsByClassName("router-content");
            element[0].classList.remove("shop-alert-margin")
            element[0].classList.remove("shop-margin")
        }
      }
    }
  },
};
</script>

<style lang="scss" scope>
#content-area {
  background-color: white;
}
.title {
  min-height: 3rem;
}
.sticky {
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid #ececec;
}
.sticky + .content-wrapper .router-content {
  margin-top: 0 !important;
}

.padder{
  @media screen and (max-width: 768px) {
    padding: .8rem 1rem
  }
}

.search-wrapper {
  width: 100%;
  max-width: 600px;
}

.navbar-custom {

  &.shop-nav .vs-con-items {
    @apply flex-col
  }

  @media screen and (max-width: 768px) {
    border-radius: 0px !important;
    padding: 0px !important;
  }

}

.shop-wrapper{
  margin-bottom: -5rem !important;
}

.shop-wrapper-fixed-search{
  margin-bottom: 5rem !important;
}

.top-alert {
  @media (max-width: 768px) {
    top: 133px;
}

}

</style>
