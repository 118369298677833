<template>
  <div>
    <div
      style="display: flex; align-items: center;"
      v-if="(checkBankDetailExists && loggedInUserType === 'clinic') && this.$isAuRegion()"
    >
      <feather-icon
        style="width: 15px; height: 15px; color: indianred!important;"
        icon="AlertTriangleIcon"
      ></feather-icon>
      <p style="color: indianred; font-weight: 600;">
        You haven't setup bank details yet!
      </p>
      <a
        @click="setupBankDetail"
        style="color: rgba(var(--vs-primary),1)!important;font-weight: 600;margin-left: 5px;cursor: pointer;"
        >Setup Now</a
      >
    </div>

    <div v-else>
      <p
        class="font-semibold"
        v-if="$store.state.AppActiveUser.userType !== 'doctorClinic'"
      >
        {{ clinicName }}
      </p>
    </div>

    <vs-popup
      class="holamundo"
      title="Setup payment method"
      :active.sync="popupShow"
      style="z-index: 51001 !important;"
      v-if="this.$isAuRegion()"
    >
      <div style="display: flex;">
        <h6 style="line-height:1.5">
          Hey! Looks like you haven't set up a default Payment Method for your
          monthly scripting. Select 'Setup Now' to continue.
        </h6>
      </div>
      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-1 mt-5">
            <div style="display:inline-block;margin-right:5px;">
              <vs-button @click="hidePopup">Later</vs-button>
            </div>
            <vs-button @click="setupBankDetail">Setup Now</vs-button>
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup
      class="holamundo"
      title="Setup Bank Details"
      :active.sync="popupActivo"
    >
      <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('accountHolderName') ? true : false"
              :success="
                !errors.first('accountHolderName') &&
                  bankDetails.accountHolderName != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="Account Holder Name"
              label-placeholder="Account Holder Name"
              name="accountHolderName"
              placeholder="Account Holder Name"
              v-model="bankDetails.accountHolderName"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("accountHolderName") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('bsb') ? true : false"
              :success="!errors.first('bsb') && bankDetails.bsb != ''"
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required|digits:6'"
              data-vv-validate-on="blur"
              data-vv-as="BSB"
              label-placeholder="BSB"
              name="bsb"
              placeholder="BSB"
              v-model="bankDetails.bsb"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("bsb") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-input
              :danger="errors.first('accountNumber') ? true : false"
              :success="
                !errors.first('accountNumber') &&
                  bankDetails.accountNumber != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              v-validate="'required'"
              data-vv-validate-on="blur"
              data-vv-as="Account Number"
              label-placeholder="Account Number"
              name="accountNumber"
              placeholder="Account Number"
              v-model="bankDetails.accountNumber"
              class="w-full mt-6"
            />
            <span class="text-danger text-sm">
              {{ errors.first("accountNumber") }}
            </span>
          </div>
        </vs-col>
      </vs-row>

      <vs-row>
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div class="w-full m-5">
            <vs-button @click="saveBankDetails" :disabled="!validateForm"
              >Save Details</vs-button
            >
          </div>
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";
import {
  ACTION_UPDATE_POPUP,
  ACTION_UPDATE_POPUP_FLAG
} from "../../../../../store/types/auth-type";

export default {
  data() {
    return {
      clinicName: this.$store.state.AppActiveUser.clinicName,
      popupActivo: false,
      popupShow: false,
      bankDetails: {
        accountHolderName: "",
        bsb: "",
        accountNumber: ""
      },
      stripe: null
    };
  },
  methods: {
    ...mapActions("clinic", ["saveClinicBankDetails"]),
    ...mapActions("auth", {
      updatePopup: ACTION_UPDATE_POPUP,
      updatePopupFlag: ACTION_UPDATE_POPUP_FLAG
    }),
    setupBankDetail() {
      // this.popupActivo = true;
      this.popupShow = false;
      let url = "/clinic/setup-bank-detail";
      if (this.$store.state.AppActiveUser.userType === "doctorClinic") {
        url = "/doctor-clinic/setup-bank-detail";
      }
      this.$router.push(url);
    },
    showPopup() {
      if (this.$store.state.auth.popupFlag) {
        this.updatePopupFlag(false);
        this.popupShow = true;
      }
    },
    hidePopup() {
      this.popupShow = false;
    },
    async saveBankDetails() {
      const self = this;
      let isValid = await self.$validator.validate();
      if (isValid) {
        self.$vs.loading();
        await self
          .saveClinicBankDetails(self.bankDetails)
          .then(res => {
            return self.stripe.confirmAuBecsDebitSetup(res.data.data.secret);
          })
          .then(confirm => {
            console.log("#######################", confirm);
            self.$vs.notify({
              title: "Success",
              text: "Bank details saved successfully !",
              color: "success"
            });
            self.$vs.loading.close();
            self.popupActivo = false;
          })
          .catch(err => {
            let errMessage = err.data.message;

            if (
              err.data.message ===
              "Invalid routing number for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx."
            )
              errMessage =
                "Invalid BSB for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx.";
            self.$vs.notify({
              title: "Failed",
              text: errMessage,
              color: "danger"
            });
            self.$vs.loading.close();
          });
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
    checkBankDetailExists() {
      if (
        this.$store.state.AppActiveUser.defaultPaymentMethod === "" &&
        this.$store.state.AppActiveUser.userType === "clinic"
      ) {
        this.showPopup();
      }

      if (
        !(
          (this.$store.state.AppActiveUser.financialDetails &&
            this.$store.state.AppActiveUser.financialDetails.accountNumber) ||
          (this.$store.state.AppActiveUser.defaultPaymentMethod ===
            "CreditCard" &&
            this.$store.state.AppActiveUser.paymentDetails.cardId)
        )
      ) {
        return true;
      }
      return false;
    },
    loggedInUserType() {
      return this.$store.state.AppActiveUser.userType;
    }
  },
  mounted() {
    this.stripe = window.Stripe(
      process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    );
  },
  watch: {
    popupShow: function(_new, _old) {
      if (_old && !_new) {
        this.updatePopup(false);
      }
    }
  }
};
</script>
