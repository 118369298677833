<template>
  <div class="pt-12" v-if="showBanner">
    <vs-card class="bannerDisplay bannerPosition bannerColor">
        <div class="flex flex-col">
           <div class="iconPosition">
            <vs-icon icon="close" size="16px" class="cursor-pointer"></vs-icon>
        </div>
       <div class="flex items-end">
         <info-icon size="2x" class="custom-class textColor"></info-icon>
          <h3 class="mt-2 ml-2 textColor">Complete Account Setup</h3>
         </div>  
        <p class="mt-2 font-light textColor">Welcome to Fresh Clinics! To get started, please ensure you complete your account setup.</p>
       <div class="mt-6 ">
            <vs-button size="default" class="buttonSetup" @click="redirectToStripeSetup">Complete Setup</vs-button>
        </div>
        </div>
    </vs-card>
  </div>
</template>

<script>
import HttpClient from "@/http/axios/financeServer"
import { InfoIcon } from 'vue-feather-icons'

export default {
    components: {
        InfoIcon
    },
    data: () => ({
        isLoading:true,
        organizationId: "",
        showBanner: false,
        billingEmail: ''
    }),
    created() {
        this.organizationId = localStorage.getItem(
          `${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`
        )

        this.getOrganizationInfo()
    },
    methods: {
        async redirectToStripeSetup() {
            try {
                if (this.$store.getters['organization/selectedOrganizationBillingEmail']) {
                    this.$vs.loading();
                    const {data: {url}} = await HttpClient.post(
                        `payments/setup`,
                        {
                            organizationId:this.organizationId,
                            billingEmail: this.billingEmail
                        }
                    )
                    window.open(url, '_blank');

                } else {
                    this.$vs.notify({
                        title: "Payment Setup",
                        text: "Update your billing email first and complete your account setup!",
                        color: "warning",
                    });
                    this.$router.push({ name: 'org-owner-billing-settings'})
                }

            } catch({response}) {
                if (response.status == 404) {
                    this.$vs.notify({
                        title: "Payment Setup",
                        text: response.data.message,
                        color: "danger",
                    });
                } else {
                    this.$vs.notify({
                        title: "Payment Setup",
                        text: "Something went wrong while setting up portal session",
                        color: "danger",
                    });
                }
        } finally {
                this.$vs.loading.close();
            }
        
        },
        async getOrganizationInfo() {
            
            const userInfo = JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`))
            this.billingEmail = userInfo.email

            this.$store.commit('organization/SET_SELECTED_BILLING_EMAIL', this.billingEmail)
            this.$store.commit('organization/SET_SELECTED_ORGANIZATION_ID', this.organizationId)
            this.organizationDefaultPaymentMethod()
        },
        async organizationDefaultPaymentMethod() {
            try {
                const {data} = await HttpClient.get(
                    `payments/org/paymentMethod?organizationId=${this.organizationId}`,
                )
                this.$store.commit('organization/SET_SELECTED_ORGANIZATION', data.data)
                this.$store.getters['organization/selectedOrganization']
                this.$store.commit('organization/SET_SELECTED_ORGANIZATION_PAYMENT_SETUP', true)
            } catch(error) {
                if (error.response.status == 404) {
                    this.showBanner = true
                } else {
                    this.$vs.notify({
                        title: "Payment Setup",
                        text: "Something went wrong file fetching payment methods",
                        color: "danger",
                    });
                }
                this.$store.commit('organization/SET_SELECTED_ORGANIZATION_PAYMENT_SETUP', false)
            }
        }
    }
};
</script>

<style scoped>
.bannerDisplay{
display: flex;
flex-direction: row;
align-items: center;
padding: 14px;
gap: 12px;
margin-top: 10px;
}
.bannerPosition{

width: 100%;
height: 128px;
left: 30px;
top: 85px;
}
.bannerColor{
   background: #84CDB81A;
;
border-radius: 6px;
}
.iconPosition{
  position: absolute;
  right: 0;
  margin-right: 45px;
 margin: 20px;
}
.textColor{
  color: #0E4333;

}
  
</style>
